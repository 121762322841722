<template>
    <div>
        <div>
            <b-checkbox switch v-model="withRanks">Affichage de tous les classements</b-checkbox>
        </div>
        <div ref="render">
        </div>
    </div>
</template>

<script>

export default {
    value(){
        return {
            columns: [],
            table: [],
            with_ranks: false,
        };
    },
    props: { 
            category : { type : Object, required: true, },
            compettirLid : { type: Number, required: true},
        },
    computed:{
        withRanks : {
            get: () => { return this.with_ranks; },
            set: (value) => {
                this.with_ranks = value;
                this.refresh();
            }
        }
    },
    methods:{
        refresh(){
 
            var table = "<table>";
            table += "<tr><th colspan=8>Précision</th><th colspan=3>Ballet</th><th colspan=2>Overall</th></tr>";
            table += "<tr><th>Fig.1</th><th>Fig.2</th><th>Fig.3</th><th>Tot. Fig.</th><th>Exec.</th><th>Cont.</th><th>Tot. routine</th><th>Total</th><th>Exec.</th><th>choreo</th><th>Tot. ballet</th><th>Total</th><th>Rk</th></tr>";

            this.category.notes.competitors.forEach(competitorNotes => {
                var competitor = this.category.competitors.find(c => c.lid == competitorNotes.competitorLid);
                table += "<tr><td colspan=2>" + competitor.firstname + "</td>";
                for(var i = 0; i < competitorNotes.total.precision.figures.details.length; i++)
                {
                    table += "<td>";
                    if(competitorNotes.total.precision.figures.details[i].completed)
                        table += competitorNotes.total.precision.figures.details[i].note;
                    table += "</td>";
                }
                table += "<td>";
                if(competitorNotes.total.precision.figures.completed)
                    table += competitorNotes.total.precision.figures.total;
                table += "</td>"; 
                if(competitorNotes.total.precision.routine.completed){
                    table += "<td>" + competitorNotes.total.precision.routine.execution + "</td>";
                    table += "<td>" + competitorNotes.total.precision.routine.content + "</td>";
                    table += "<td>" + competitorNotes.total.precision.routine.total + "</td>";
                }
                else{
                    table += "<td /><td /><td />"
                }                
                table += "<td>" + competitorNotes.total.precision.total + "</td>";

                if(competitorNotes.total.ballet.completed)
                {
                    table += "<td>" + competitorNotes.total.ballet.execution + "</td>";
                    table += "<td>" + competitorNotes.total.ballet.choreo + "</td>";
                    table += "<td>" + competitorNotes.total.ballet.total + "</td>";
                }
                else{
                    table += "<td /><td /><td />"
                }       
                
                table += "<td>" + competitorNotes.total.note + "</td>";
                table += "<td>" + competitorNotes.total.rank + "</td>";

                table += "</tr>";
            });
            
            table += "</table>";

            this.$refs.render.innerHTML = table;
        }
    }
}
</script>

<style scoped>
    table, th, td { font-size: 0.3rem !important;}
</style>